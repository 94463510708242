.page-restaurant {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	& > .left {
		width: 60%;
		padding-right: 1rem;
		.table {
			&-body {
				tr {
					td {
						width: 50%;
					}
				}
			}
		}
	}
	& > .right {
		width: 40%;
		padding-left: 1rem;
	}
	&-section {
		h2 {
			margin-top: 0;
		}
		table {
			width: 100%;
		}
	}
	input {
		text-align: center;
		width: max-content;
	}
}

@media screen and (max-width: 1200px) {
	.page-restaurant {
		flex-direction: column;
		&-section {
			&-header {
				&-heading {
					text-align: center;
				}
			}
		}
		& > * {
			width: 100% !important;
			padding: 0 !important;
			.table {
				width: 100%;
				overflow-x: auto;
				&-container {
					width: max-content;
					max-width: 100%;
					overflow-x: hidden;
					margin-left: auto;
					margin-right: auto;
				}
			}
		}
	}
}
